import type {
  ClinicListElementMenu,
  ClinicListElementMenuSource,
  MenuListElement,
  MenuCardType,
  SavedMenu,
  Menu,
  MenuWithLowestPrice,
  ClinicMenu,
} from './models'

export const formatClinicListElementMenu = (
  menu: ClinicListElementMenuSource
): ClinicListElementMenu => {
  return {
    id: menu.id,
    name: menu.name,
    menuPlans: menu.menuPlans,
    lowestPrice: menu.menuPlans.reduce<number | null>((minPrice, plan) => {
      if (
        plan.priceType === 'normal' &&
        plan.price !== null &&
        (minPrice === null || plan.price < minPrice)
      ) {
        return plan.price
      }
      return minPrice
    }, null),
    treatments: menu.menuTreatments.map((menuTreatment) => {
      return menuTreatment.treatment
    }),
  }
}

export const fotmatMenuWithLowestPrice = (menu: Menu): MenuWithLowestPrice => {
  return {
    ...menu,
    lowestPrice: menu.menuPlans.reduce<number | null>((minPrice, plan) => {
      if (
        plan.priceType === 'normal' &&
        plan.price !== null &&
        (minPrice === null || plan.price < minPrice)
      ) {
        return plan.price
      }
      return minPrice
    }, null),
  }
}

export const formatMenuCardType = (
  menu: ClinicListElementMenu | ClinicListElementMenuSource | MenuListElement,
  clinic: {
    id: number
    name: string | null
    reportsCount: number
    totalReportRating: number
    photos: {
      imageUrl: string | null
    }[]
  }
): MenuCardType => {
  const clinicPhotoUrl =
    (clinic.photos.length > 0 ? clinic.photos[0].imageUrl : '/noimage.jpg') ||
    '/noimage.jpg'
  const clinicReportsAverage =
    clinic.reportsCount === 0
      ? 0
      : clinic.totalReportRating / clinic.reportsCount
  const lowestPrice = (() => {
    if ('lowestPrice' in menu) {
      return menu.lowestPrice
    } else {
      return menu.menuPlans.reduce<number | null>((minPrice, plan) => {
        if (
          plan.priceType === 'normal' &&
          plan.price !== null &&
          (minPrice === null || plan.price < minPrice)
        ) {
          return plan.price
        }
        return minPrice
      }, null)
    }
  })()
  const treatments = (() => {
    if ('treatments' in menu) {
      return menu.treatments
    } else {
      return menu.menuTreatments.map((menuTreatment) => {
        return menuTreatment.treatment
      })
    }
  })()
  return {
    id: menu.id,
    name: menu.name,
    menuPlans: menu.menuPlans,
    lowestPrice,
    treatments,
    clinicId: clinic.id,
    clinicPhotoUrl,
    clinicName: clinic.name,
    clinicReportsCount: clinic.reportsCount,
    clinicReportsAverage,
  }
}

export const formatToSavedMenu = (menu: MenuCardType): SavedMenu => {
  return {
    ...menu,
    savedAt: new Date(),
    editedAt: new Date(),
    memo: '',
  }
}

export const formatToMenuListElement = (menu: ClinicMenu): MenuListElement => {
  const priceArray = menu.menuPlans
    .filter((plan) => plan.price !== null && plan.priceType === 'normal')
    .map((plan) => plan.price) as number[]
  const lowestPrice = priceArray.length > 0 ? Math.min(...priceArray) : null
  return {
    id: menu.id,
    name: menu.name || '',
    affiliationLink: menu.affiliationLink || '',
    detail: menu.detail,
    menuPlans: menu.menuPlans,
    treatments: menu.menuTreatments.map((mt) => mt.treatment),
    lowestPrice,
  }
}
