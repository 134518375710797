import type {
  Doctor,
  DoctorMinData,
  DoctorMinDataSource,
  DoctorSource,
} from '~/typeEntities/stores/Doctor/models'
import { formatArticleListElement } from '~/typeEntities/stores/Article'

export const formatDoctorMinData = (
  doctorMinDataSource: DoctorMinDataSource
): DoctorMinData => {
  return {
    id: doctorMinDataSource.id,
    name: doctorMinDataSource.name,
    thumbnailData: doctorMinDataSource.thumbnailData,
    thumbnailUrl: doctorMinDataSource.thumbnailUrl,
    introduction: doctorMinDataSource.introduction,
    career: doctorMinDataSource.career,
    jobTitle: doctorMinDataSource.jobTitle,
    answersCount: doctorMinDataSource.answersCount,
    reportsCount: doctorMinDataSource.reportsCount,
    totalReportRating: doctorMinDataSource.totalReportRating,
    articles: doctorMinDataSource.articleDoctors.map((articleDoctor) => {
      return formatArticleListElement(articleDoctor.article)
    }),
    qualifications: doctorMinDataSource.qualifications,
  }
}

export const formatDoctor = (doctorSource: DoctorSource): Doctor => {
  const directedStore = doctorSource.doctorAssignments.find(
    (doctorAssignment) => doctorAssignment.jobPosition === 'director'
  )?.store
  return {
    ...formatDoctorMinData(doctorSource),
    answers: doctorSource.answers,
    directedStore: directedStore
      ? {
          id: directedStore.id,
          name: directedStore.name,
          accessText: directedStore.accessText,
          address: directedStore.address,
          phoneNumber: directedStore.phoneNumber,
          reportsCount: directedStore.reportsCount,
          totalReportRating: directedStore.totalReportRating,
          totalWeight: directedStore.totalWeight,
          storePhotos: directedStore.storePhotos,
          businessDays: directedStore.businessDays,
          businessHoursNotes: directedStore.businessHoursNotes,
        }
      : null,
    stores: doctorSource.doctorAssignments.map((doctorAssignment) => {
      return {
        id: doctorAssignment.store.id,
        name: doctorAssignment.store.name,
        accessText: doctorAssignment.store.accessText,
        address: doctorAssignment.store.address,
        phoneNumber: doctorAssignment.store.phoneNumber,
        reportsCount: doctorAssignment.store.reportsCount,
        totalReportRating: doctorAssignment.store.totalReportRating,
        totalWeight: doctorAssignment.store.totalWeight,
        storePhotos: doctorAssignment.store.storePhotos,
        businessDays: doctorAssignment.store.businessDays,
        businessHoursNotes: doctorAssignment.store.businessHoursNotes,
      }
    }),
    youtubeVideos: doctorSource.doctorYoutubes.map((doctorYoutube) => {
      return {
        id: doctorYoutube.youtubeVideo.id,
        title: doctorYoutube.youtubeVideo.title,
        description: doctorYoutube.youtubeVideo.description,
        videoId: doctorYoutube.youtubeVideo.videoId,
      }
    }),
  }
}
